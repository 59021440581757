/* eslint-disable */

// const results = resultsLocalStorage.features_finger_id

let index = [{
  title: 'الصفحة الرئيسية',
  icon: 'fa-home',
  to: 'home',
},
// accountant
{
  title: 'الحسابات',
  icon: 'fa-file-contract',
  href: "https://counting.braum-iq.com/",
},

//classSchool
{
  title: 'الصف والشعبة',
  icon: 'fa-school',
  to: 'classSchool',
},

// subjects
{
  title: 'المواد',
  icon: 'fa-book',
  to: 'subject',
},

//teachers
{
  title: 'الاساتذة',
  icon: 'fa-chalkboard-teacher',
  to: 'teachers',
},


//students
{
  title: 'الطلاب',
  icon: 'fa-user-graduate',
  children: [{
    title: 'الطلاب المسجلين',
    to: 'students',
    // alias: ['/studentInClass', '/addStudent', '/studentProfile'],
  },
  {
    title: 'جميع الطلاب',
    to: { name: 'allStudent' },
  },
  {
    title: 'الطلاب الموقوفين',
    to: { name: 'stopedStudent' },
  },
  {
    title: 'الطلاب المحذوفين',
    to: { name: 'deletedStudent' },
  },
  ],
},

//absence
{
  title: 'الحضور',
  icon: ' fa-clock',
  children: [{
    title: 'عرض',
    to: 'absenceShow',
  },
  {
    title: 'اضافة',
    to: 'absenceAdd',
  },
  ],
},

//Review
{
  title: 'التقييم',
  icon: 'fa fa-comments',
  children: [{
    title: 'عرض',
    to: 'showReview',
  },
  {
    title: 'اضافة',
    to: 'reviewAdd',
  },
  ],
},

//notification
{
  title: 'الاشعارات',
  icon: 'fa-bell',
  to: 'notification',
},

// cloth
{
  title: 'ملابس',
  icon: 'fa-tshirt',
  to: 'cloth',
},

// bed
{
  title: 'غفوة',
  icon: 'fa-bed',
  to: 'bed',
},

// baby
{
  title: 'الحفاض',
  icon: ' fa-baby-carriage',
  to: 'baby',
},


// food
{
  title: 'غذاء',
  icon: 'fa-utensils',
  to: 'food',
},

// lesson
{
  title: 'دروس',
  icon: 'fa-chalkboard',
  to: 'lesson',
},

// training
{
  title: 'تدريب',
  icon: 'fa-chalkboard-teacher',
  to: 'training',
},

// fingerprint
{
  title: 'البصمة',
  icon: 'fa-fingerprint',
  to: 'fingerPrint',
},

// ads
// {
//     title: 'الاعلانات',
//     icon: 'fa-newspaper',
//     to: 'ads',
// },

//latestNews
{
  title: 'طلبات التعيين',
  icon: 'fa fa-paper-plane',
  to: 'hireRequest',
},

// drivers
{
  title: 'السواق',
  icon: 'fa-bus',
  to: 'drivers',
},



//latestNews
{
  title: 'اخر الاخبار',
  icon: 'fa-newspaper',
  to: 'latestNews',
},


//connectUs
{
  title: 'اتصل بنا',
  icon: 'fa-phone-square',
  to: 'connectUs',
},
]

export default index
