/* eslint-disable */
export default [{
  title: 'الصفحة الرئيسية',
  icon: 'fa-home',
  to: 'adminHome',
},

//adminSchool
{
  title: 'الروضة',
  icon: 'fa-school',
  to: 'adminAddSchool',
},

// ads
{
  title: 'الاعلانات',
  icon: 'fa-newspaper',
  to: 'ads',
},

//adminSubject
// {
//     title: 'المواد الدراسية',
//     icon: 'fa-book',
//     to: 'adminSubject',
// },

//adminSubject
{
  title: 'طلبات الانضمام',
  icon: 'fa-clipboard-list',
  to: 'adminJoinUs',
},

//adminContactUs
{
  title: 'اتصل بنا',
  icon: 'fa-phone-square',
  to: 'adminContactUs',
},
]
